import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Path, PathValue, useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from './useFiltersCache';
import CryptoJS from 'crypto-js';

type useParamsProps<T extends Record<string, unknown>> = {
  params: { label: keyof T; isEncrypted?: boolean }[];
  extraParams?: { label: string; change: (value?: string) => void }[];
  formatFilters?: (filters: Partial<T>) => Record<string, unknown>;
  tab: string;
  filterChanges?: (cookies: unknown) => unknown;
  afterReset?: () => void;
  cookieFiltersName: string;
};

const useParamsAndCookies = <T extends Record<string, unknown>>({
  tab,
  formatFilters,
  params,
  extraParams,
  filterChanges,
  afterReset,
  cookieFiltersName,
}: useParamsProps<T>) => {
  const [cookies] = useCookies([cookieFiltersName]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue, reset } = useFormContext<T>();
  const filters = useWatch<T>();
  useFiltersCache({
    tab,
    filters: formatFilters ? formatFilters(filters as Partial<T>) : filters,
  });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';
    const newSearchParams = new URLSearchParams(searchParams);
    let hasParam = false;

    extraParams?.forEach((param) => {
      let value = newSearchParams.get(param.label);
      if (value) {
        if (!hasParam) {
          hasParam = true;
          reset();
          if (afterReset) {
            afterReset();
          }
        }

        param.change(value);
        newSearchParams.delete(param.label);
      }
    });

    params.forEach((param) => {
      let value = newSearchParams.get(String(param.label));
      if (value) {
        if (param.isEncrypted) {
          const bytes = CryptoJS.AES.decrypt(value, secretKey);
          value = bytes.toString(CryptoJS.enc.Utf8);
        }

        if (!hasParam) {
          hasParam = true;
          reset();
          if (afterReset) {
            afterReset();
          }
        }

        newSearchParams.delete(String(param.label));
        setValue(param.label as Path<T>, value as PathValue<T, Path<T>>);
      }
    });

    if (hasParam) {
      setSearchParams(newSearchParams);
      setRendered(true);
    } else if (!rendered && cookies[cookieFiltersName]?.tab === tab) {
      setRendered(true);
      let cookieFilters = cookies[cookieFiltersName]?.filters;

      if (filterChanges) {
        cookieFilters = filterChanges(cookieFilters);
      }

      for (const i in cookieFilters) {
        setValue(i as Path<T>, cookieFilters[i]);
      }
    }
  }, [searchParams, rendered, cookies[cookieFiltersName]]);
};

export default useParamsAndCookies;
