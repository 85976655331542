import { useMutation } from '@tanstack/react-query';
import { arrayComparison } from 'apps/tmr-frontend/src/utils/utils';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeeTimesheet,
  TimeSheetArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import {
  EmployeePCReimbursement,
  PCReimbursementArgs,
} from '../../../Interfaces/Accounting/Payroll.interface';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  postPCReimbursementDraft,
  postTimesheetDraft,
  putPCReimbursementDraft,
  putTimesheetApproveDraft,
  putTimesheetDraft,
  putTimesheetRejectDraft,
  putTimesheetSubmitDraft,
} from '../../../services/PayrollService';
import {
  checkReimbursementContent,
  prepareReimbursementPayload,
} from '../PettyCashReimbursement/utils';
import {
  checkTimesheetContent,
  generateConfirmationMessage,
  prepareTimesheetPayload,
} from './util';

type TimesheetProcessProps = {
  approvalMode: boolean;
  timesheet: EmployeeTimesheet[];
  reimbursement: EmployeePCReimbursement[];
  periodStart: Date;
  periodEnd: Date;
  defaultTimesheetID?: string;
  defaultReimbursementID?: string;
  isSubmitted?: boolean;
  blockAll?: boolean;
  access: Access;
  afterCheck: (showErrors: boolean) => void;
  afterUpdate: (
    data: EmployeePCReimbursement[],
    newSizeLimit: number,
    id: string
  ) => void;
  step: number;
  changeStep: (step: number) => void;
  resetDraft: () => void;
  filesToDeleteDefault: Record<number, string[]>;
};

const TimesheetBundleProcess = ({
  defaultTimesheetID,
  defaultReimbursementID,
  approvalMode,
  isSubmitted,
  timesheet,
  reimbursement,
  periodStart,
  periodEnd,
  blockAll,
  access,
  afterCheck,
  afterUpdate,
  step,
  changeStep,
  resetDraft,
  filesToDeleteDefault,
}: TimesheetProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [timesheetID, setTimesheetID] = useState<string>(
    defaultTimesheetID ?? ''
  );
  const [reimbursementID, setReimbursementID] = useState<string>(
    defaultReimbursementID ?? ''
  );
  const [blockSave, setBlockSave] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(
    isSubmitted ?? !defaultTimesheetID
  );
  const [blockApproval, setBlockApproval] = useState(
    approvalMode ? false : !defaultTimesheetID
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const prevTimesheet = useRef(timesheet);
  const prevReimbursement = useRef(reimbursement);

  const saveReimbursement = useMutation({
    mutationFn: ({
      draftID,
      draft,
      timesheetID,
    }: {
      draftID: string;
      draft: PCReimbursementArgs;
      timesheetID?: string;
    }) => {
      if (draftID) {
        return putPCReimbursementDraft(draftID, draft, !!approvalMode);
      }

      return postPCReimbursementDraft(selectedCompany!.id, draft, timesheetID);
    },
    onSuccess: (data) => {
      let message = 'The data was saved successfully!';
      if (data.error.length) {
        message = `The data was saved with the exception of the following files: ${data.error.join(
          ', '
        )}.`;
      }

      showToast('success', toast, 'Save Payroll Timesheet', message, 3000);

      afterUpdate(
        data.draft.employees,
        data.draft.attachmentsSize,
        data.draft.id
      );

      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);

      if (!reimbursementID) {
        setReimbursementID(data.draft.id);
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Save Payroll Timesheet',
        errorData?.message || `The data couldn't be saved`,
        3000
      );
    },
  });

  const saveReimbursementDraft = (
    draftID: string,
    draft: EmployeePCReimbursement[],
    timesheetID: string
  ) => {
    const processedDraft = prepareReimbursementPayload(draft);

    processedDraft.map((emp) => {
      const prevAttachments = filesToDeleteDefault[emp.empID];

      if (prevAttachments) {
        emp.deleteAttachments = prevAttachments;
      }
    });

    saveReimbursement.mutate({ draftID, timesheetID, draft: processedDraft });
  };

  const saveTimesheet = useMutation({
    mutationFn: ({
      draftID,
      timesheet,
    }: {
      draftID: string;
      timesheet: TimeSheetArgs;
    }) => {
      if (draftID) {
        return putTimesheetDraft(
          draftID,
          timesheet,
          periodStart,
          periodEnd,
          !!approvalMode
        );
      }

      return postTimesheetDraft(
        selectedCompany!.id,
        timesheet,
        periodStart,
        periodEnd
      );
    },
    onSuccess: (data) => {
      if (!timesheetID) {
        setTimesheetID(data);
      }

      saveReimbursementDraft(reimbursementID, reimbursement, data);
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Payroll Timesheet',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const saveDraft = (draftID: string, timesheet: EmployeeTimesheet[]) => {
    const processedDraft = prepareTimesheetPayload(timesheet);
    saveTimesheet.mutate({ draftID, timesheet: processedDraft });
  };

  const submitTimesheet = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetSubmitDraft(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);

      showToast(
        'success',
        toast,
        'Submit Payroll Timesheet',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Payroll Timesheet',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveTimesheet = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetApproveDraft(draftID);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Payroll Timesheet`,
        `The draft was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Payroll Timesheet`,
        errorData?.message || `The draft couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putTimesheetRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Payroll Timesheet`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Payroll Timesheet`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  const formErrorMessage = (text: string) => {
    showToast('error', toast, `Payroll Timesheet`, text, 3000);
  };

  useEffect(() => {
    if (prevTimesheet.current !== timesheet && !hasBeenApproved) {
      setBlockSave(false);
      setBlockSubmit(true);
      setBlockApproval(true);
      prevTimesheet.current = timesheet;
    }
  }, [timesheet, hasBeenApproved]);

  useEffect(() => {
    if (prevReimbursement.current !== reimbursement && !hasBeenApproved) {
      const areEquals = arrayComparison(
        prevReimbursement.current,
        reimbursement
      );

      if (!areEquals) {
        setBlockSave(false);
        setBlockSubmit(true);
        setBlockApproval(true);
        prevReimbursement.current = reimbursement;
      }
    }
  }, [reimbursement, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <div className="sm:mr-auto">
        <ConfirmationDialog
          tagKey="draft-clear"
          Button={
            <LoadingButton
              label="Clear Form"
              fontSize="text-xl"
              bgColor="bg-orange-500"
              isLoading={false}
              type="button"
            />
          }
          onConfirm={() => {
            resetDraft();
          }}
          message={'Are you sure you want to clear the form data?'}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        <Button
          type="button"
          className={`w-10rem bluwaiBlue border-0 flex m-auto py-3 relative`}
          onClick={() => {
            if (step === 0) {
              changeStep(1);
            } else {
              changeStep(0);
            }
          }}
        >
          <span className={`mx-auto text-xl text-white`}>
            {step === 0 ? 'Next' : 'Previous'}
          </span>
        </Button>
        <LoadingButton
          label="Save"
          fontSize="text-xl"
          bgColor="buttonSecondary"
          isLoading={saveTimesheet.isLoading || saveReimbursement.isLoading}
          onClick={() => saveDraft(timesheetID, timesheet)}
          disabled={
            blockAll ||
            blockSave ||
            saveTimesheet.isLoading ||
            saveReimbursement.isLoading
          }
        />
        {!approvalMode && access.editable === 'approval' && (
          <ConfirmationDialog
            Button={
              <LoadingButton
                label="Submit"
                fontSize="text-xl"
                isLoading={submitTimesheet.isLoading}
                disabled={blockAll || blockSubmit || submitTimesheet.isLoading}
                type="button"
              />
            }
            onConfirm={() => {
              const { missingPhases } = checkTimesheetContent(timesheet);
              if (checkReimbursementContent(reimbursement) && !missingPhases) {
                submitTimesheet.mutate(timesheetID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage(
                  `The ${
                    missingPhases ? 'Timesheet' : 'Reimbursement'
                  } form is missing some fields!`
                );
              }
            }}
            message={generateConfirmationMessage(
              `Are you sure you want to submit this draft?`,
              timesheet
            )}
          />
        )}
        {approvalMode && access.shouldApprove && (
          <ConfirmationDialog
            tagKey="draft-rejection"
            visibility={visibility}
            Button={
              <LoadingButton
                label={'Reject'}
                fontSize="text-xl"
                bgColor="bluwaiRed"
                isLoading={rejectRequest.isLoading}
                disabled={blockAll || blockApproval || rejectRequest.isLoading}
                type="button"
                onClick={() => setVisibility(true)}
              />
            }
            contentClassName="pb-0"
            onConfirm={() => {}}
            acceptClassName="hidden"
            rejectClassName="hidden"
            onHide={() => setVisibility(false)}
            message={
              <RejectionField
                onReject={() => {
                  setVisibility(false);
                }}
                onConfirm={(reason) => {
                  rejectRequest.mutate({ draftID: timesheetID, reason });
                  setVisibility(false);
                }}
              />
            }
          />
        )}
        {(approvalMode
          ? access.shouldApprove
          : access.editable === 'no-approval') && (
          <ConfirmationDialog
            tagKey="draft-approval"
            Button={
              <LoadingButton
                label={approvalMode ? 'Approve' : 'Upload'}
                fontSize="text-xl"
                bgColor="bg-green-500"
                isLoading={approveTimesheet.isLoading}
                disabled={
                  blockAll || blockApproval || approveTimesheet.isLoading
                }
                type="button"
              />
            }
            onConfirm={() => {
              const { missingPhases } = checkTimesheetContent(timesheet);
              if (checkReimbursementContent(reimbursement) && !missingPhases) {
                approveTimesheet.mutate(timesheetID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage(
                  `The ${
                    missingPhases ? 'Timesheet' : 'Reimbursement'
                  } form is missing some fields!`
                );
              }
            }}
            message={generateConfirmationMessage(
              `Are you sure you want to ${
                approvalMode ? 'approve' : 'upload'
              } this draft?`,
              timesheet
            )}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TimesheetBundleProcess;
