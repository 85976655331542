import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { roundHours } from './util';

type RegularHoursInputProps = {
  id: string;
  regularHrs: number;
  onlyPercentage?: boolean;
  companyHours: number;
  onChange: (value?: number) => void;
  blockPercentage?: boolean;
  disabled?: boolean;
};

const RegularHoursInput = ({
  id,
  regularHrs,
  onlyPercentage,
  companyHours,
  onChange,
  blockPercentage,
  disabled,
}: RegularHoursInputProps) => {
  const [prevValue, setPrevValue] = useState(regularHrs ? `${regularHrs}` : '');
  const [inputValue, setInputValue] = useState(
    regularHrs ? `${regularHrs}` : ''
  );
  const [onFocus, setOnFocus] = useState(false);
  const toast = useRef<Toast>(null);
  const numberRegex = /^(-?(\d*(\.\d{0,3})?))$/;
  const numberPercentageRegex = /^(-?(\d+(\.\d{0,3})?|\.\d{0,3})?%?|-%|%)$/;

  useEffect(() => {
    if (regularHrs) {
      setInputValue(`${regularHrs}`);
      setPrevValue(`${regularHrs}`);
    } else {
      setInputValue('');
      setPrevValue('');
    }
  }, [regularHrs]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <span className="relative h-full">
        {(onFocus && onlyPercentage && !blockPercentage) && (
          <div className="absolute blackText text-standard top-0 right-4">
            %
          </div>
        )}
        <InputText
          type="text"
          id={id}
          keyfilter={
            onlyPercentage || blockPercentage
              ? numberRegex
              : numberPercentageRegex
          }
          validateOnly={true}
          onFocus={(event) => {
            const result = event.target.value;

            if (onlyPercentage && !blockPercentage && result) {
              const numberValue = parseFloat(result);
              const percentage = (numberValue * 100) / companyHours;
              setInputValue(`${roundHours(percentage)}`);
              setPrevValue(`${roundHours(percentage)}`);
            }
            setOnFocus(true);
          }}
          onInput={(event, validatePattern) => {
            const target = event.currentTarget;
            const hasLength = target.value.length > 0;

            if (validatePattern && hasLength) {
              setPrevValue(target.value);
              return;
            }

            if (hasLength) {
              target.value = prevValue;
            }
          }}
          className="w-full printHide blackText text-standard text-center"
          value={inputValue}
          onChange={(e) => {
            let value = e.target.value;

            if (onlyPercentage && value && !blockPercentage) {
              value = `${value}%`;
            }

            if (value?.includes('%')) {
              const cleanedValue = parseFloat(value.replace('%', ''));

              if (cleanedValue < 0 || cleanedValue > 100) {
                showToast(
                  'error',
                  toast,
                  'Payroll Timesheet',
                  'The percentage amount must be between 0% and 100%.',
                  3000
                );

                return;
              }
            }

            setInputValue(e.target.value);
          }}
          onBlur={(e) => {
            let valueText = e.target.value;

            if (onlyPercentage && valueText && !blockPercentage) {
              valueText = `${valueText}%`;
            }

            let hours = 0;
            if (valueText?.includes('%')) {
              const cleanedValue = parseFloat(valueText.replace('%', ''));
              hours = (companyHours * cleanedValue) / 100;
            } else {
              hours = parseFloat(valueText);
            }

            setInputValue(hours ? `${roundHours(hours)}` : '');
            setPrevValue(hours ? `${roundHours(hours)}` : '');
            onChange(roundHours(hours) ?? undefined);
            setOnFocus(false);
          }}
          disabled={disabled}
        />
      </span>
      {(blockPercentage || !onlyPercentage) && (
        <Tooltip
          target={`#${id}`}
          content={
            blockPercentage
              ? 'This is an hourly employee. Enter hours instead of percentage'
              : `Enter amount or percentage by using '%' at the end`
          }
          position="top"
          event="focus"
        />
      )}
      <span className="printMedia printShow">{inputValue}</span>
    </React.Fragment>
  );
};

export default RegularHoursInput;
