import {
  AgedPayable,
  Payable,
  DraftBatchPayment,
} from '../../Interfaces/Accounting/AccountsPayables.interfaces';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';

import {
  fetchOpenVendors,
  fetchUnapprovedVendors,
  fetchVendors,
} from '../../services/vendorsService';

export function getVendorFetch(
  reportType: string,
  company: Company,
  signal: AbortSignal | undefined
) {
  switch (reportType) {
    case 'openPayables':
      return fetchOpenVendors(company, signal);
    case 'agedPayables':
      return fetchOpenVendors(company, signal);
    case 'unapprovedInvoices':
      return fetchUnapprovedVendors(company, signal);
    case 'openPayablesPerJob':
      return fetchOpenVendors(company, signal, true);
    default:
      return fetchVendors({ company }, signal);
  }
}

export function processPayableList(data: Payable[]) {
  const newData: Payable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = {
    gross: 0,
    amount: 0,
    tax: 0,
    discount: 0,
    net: 0,
    batchDisabled: true,
  };

  data.forEach((payable: Payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.gross += payable.Gross;
      acumulator.amount += payable.PayableAmount ?? 0;
      acumulator.tax += payable.Tax;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount + (payable.Retainage ?? 0);
      acumulator.batchDisabled =
        acumulator.batchDisabled && !!payable.BatchDisabled;
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
      } as Payable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        gross: payable.Gross,
        amount: payable.PayableAmount ?? 0,
        tax: payable.Tax,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount + (payable.Retainage ?? 0),
        batchDisabled: !!payable.BatchDisabled,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
      } as Payable);
    }
  });

  return newData;
}

export function processPayableListPerJob(data: Payable[]) {
  const newData: Payable[] = [];
  let currentJob = data[0]?.Job;
  let currentJobName = data[0]?.Description;
  let acumulator = { gross: 0, discount: 0, net: 0, retainage: 0 };

  data.forEach((payable: Payable, idx: number) => {
    if (currentJob === payable.Job) {
      acumulator.gross += payable.Gross;
      acumulator.retainage += payable.Retainage ?? 0;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount + (payable.Retainage ?? 0);
    } else {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);

      currentJob = payable.Job;
      currentJobName = payable.Description;
      acumulator = {
        gross: payable.Gross,
        retainage: payable.Retainage ?? 0,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount + (payable.Retainage ?? 0),
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);
    }
  });

  return newData;
}

export function processAgedPayablePerVendor(data: AgedPayable[]) {
  const newData: AgedPayable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = { current: 0, over30: 0, over60: 0, over90: 0, holdRet: 0 };

  data.forEach((payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.current += payable.Current;
      acumulator.over30 += payable.Over30;
      acumulator.over60 += payable.Over60;
      acumulator.over90 += payable.Over90;
      acumulator.holdRet += payable.HoldRet;
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        current: payable.Current,
        over30: payable.Over30,
        over60: payable.Over60,
        over90: payable.Over90,
        holdRet: payable.HoldRet,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);
    }
  });

  return newData;
}

export const getVendorKeyPerCompany = (companySource: CompanySource) => {
  if (companySource === CompanySource.ViewPoint) {
    return 'code';
  }

  return 'id';
};

export const buildDefaultBatch = (
  payables: Payable[],
  batchPayments: DraftBatchPayment[]
): DraftBatchPayment[] => {
  const vendorTotals = [];
  const vendorCounter: Record<number, number> = {};

  const batchJson = batchPayments.reduce((acc, payment) => {
    const amount = vendorCounter[payment.vendor] ?? 0;
    vendorCounter[payment.vendor] = amount + 1;

    acc[`${payment.vendor}-${payment.apref}-${payment.payType}`] = true;

    return acc;
  }, {} as Record<string, boolean>);

  let currentVendor = payables[0]?.VendorCode;
  const vendorDisabledCounter: Record<number, number> = {};
  const vendorPayablesCounter: Record<number, number> = {};

  for (const payable of payables) {
    const payableVendor = payable.VendorCode ?? 0;
    const totalPayables = vendorPayablesCounter[payableVendor] ?? 0;
    const disabledPayables = vendorDisabledCounter[payableVendor] ?? 0;

    if (!payable.Reference) {
      const payablesInBatch = disabledPayables + vendorCounter[payableVendor];
      if (totalPayables === payablesInBatch) {
        vendorTotals.push(transformPayableToPayment(payable));
      }
    } else {
      if (
        batchJson[
          `${payable.VendorCode}-${payable.Reference}-${payable.PayType}`
        ]
      ) {
        vendorTotals.push(transformPayableToPayment(payable));
      }

      if (currentVendor === payableVendor) {
        vendorPayablesCounter[payableVendor] = totalPayables + 1;
        vendorDisabledCounter[payableVendor] =
          disabledPayables + (payable.BatchDisabled ? 1 : 0);
      } else {
        currentVendor = payableVendor;
        vendorPayablesCounter[payableVendor] = 1;
        vendorDisabledCounter[payableVendor] = payable.BatchDisabled ? 1 : 0;
      }
    }
  }

  return vendorTotals;
};

export const transformPayableToPayment = (payable: Payable) => {
  const payment = {
    apref: payable.Reference,
    aptrans: payable.TransCode,
    vendor: payable.VendorCode ?? 0,
    payType: payable.PayType,
    amount: payable.NetAmount + (payable.Retainage ?? 0),
  };

  return payment;
};

export const comparePayableWithPayment = (
  payable: Payable,
  batchPayment: DraftBatchPayment
) => {
  return (
    batchPayment.apref === payable.Reference &&
    batchPayment.aptrans === payable.TransCode &&
    batchPayment.vendor === payable.VendorCode &&
    batchPayment.payType === payable.PayType
  );
};

export const comparePayments = (
  batchPayment1: DraftBatchPayment,
  batchPayment2: DraftBatchPayment
) => {
  return (
    batchPayment1.apref === batchPayment2.apref &&
    batchPayment1.aptrans === batchPayment2.aptrans &&
    batchPayment1.vendor === batchPayment2.vendor &&
    batchPayment1.payType === batchPayment2.payType
  );
};
