import { SCFilterFields } from '../../Interfaces/Accounting/Subcontracts.interfaces';
import { SubcontractsFilters } from './SCFilters';
import useParams from '../../hooks/useParamsAndCookies';

const params: { label: SCFilterFields; isEncrypted?: boolean }[] = [
  { label: 'reportType' },
  { label: 'job', isEncrypted: true },
  { label: 'subcontract', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (filters: Partial<SubcontractsFilters>) => {
  return {
    ...filters,
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

const SCParams = () => {
  useParams<SubcontractsFilters>({
    tab: 'SC',
    params,
    formatFilters,
    filterChanges: (values) => {
      const cookies = values as {
        dates: (string | null)[];
      };
      const { dates, ...otherFields } = cookies;
      const newCookies = otherFields as Record<string, unknown>;

      if (dates) {
        const start = new Date(dates[0] ?? '');
        const end = dates[1] ? new Date(dates[1]) : null;
        newCookies['dates'] = [start, end];
      }

      return newCookies;
    },
    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default SCParams;
