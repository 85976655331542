import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ReimbursementFilters } from './PCReimbursementFilters';

const PCReimbursementParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<ReimbursementFilters>();

  useEffect(() => {
    let value = searchParams.get('submittedID');
    if (value) {
      setValue('submittedID', value);
      setSearchParams();
    }
  }, [searchParams]);

  return <span />;
};

export default PCReimbursementParams;
