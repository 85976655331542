import useParams from '../../hooks/useParamsAndCookies';
import { PayrollFilters } from './PRFilters';

const PRParams = () => {
  useParams<PayrollFilters>({
    tab: 'PR',
    params:[],
    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default PRParams;
