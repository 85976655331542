import useParams from '../../hooks/useParamsAndCookies';
import { fetchAccountsPayablesFilters } from './APFilters';

const params: {
  label: keyof fetchAccountsPayablesFilters;
  isEncrypted?: boolean;
}[] = [{ label: 'reportType' }, { label: 'account' }];

const formatFilters = (filters: Partial<fetchAccountsPayablesFilters>) => {
  return {
    ...filters,
    reportType: filters.reportType ?? '',
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

type APParamsProps = {
  changeCreateBatch: (data: boolean) => void;
};

const APParams = ({ changeCreateBatch }: APParamsProps) => {
  useParams<fetchAccountsPayablesFilters>({
    tab: 'AP',
    params,
    extraParams: [
      {
        label: 'paymentBatch',
        change: (value) => {
          changeCreateBatch(value === 'true');
        },
      },
    ],
    formatFilters,
    filterChanges: (values) => {
      const cookies = values as { dates: (string | null)[] };
      const { dates, ...otherFields } = cookies;
      const newCookies = otherFields as Record<string, unknown>;
      if (dates) {
        const start = new Date(dates[0] ?? '');
        const end = dates[1] ? new Date(dates[1]) : null;
        newCookies['dates'] = [start, end];
      }

      return newCookies;
    },
    afterReset: () => {
      changeCreateBatch(false);
    },
    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default APParams;
