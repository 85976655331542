import {
  DraftBatchPayment,
  Payable,
} from 'apps/tmr-frontend/src/app/Interfaces/Accounting/AccountsPayables.interfaces';
import { Checkbox } from 'primereact/checkbox';
import { transformPayableToPayment } from '../../apUtil';

type PaymentBatchCheckboxProps = {
  isSelected: boolean;
  openPayable: Payable;
  allPayables: Payable[];
  addPayableToBatch: (
    payable: DraftBatchPayment,
    vendorAmount: number,
    vendorTotal: DraftBatchPayment
  ) => void;
  removePayableFromBatch: (payable: DraftBatchPayment) => void;
  addVendorToBatch: (
    payment: DraftBatchPayment,
    allPayables: Payable[]
  ) => void;
  removeVendorFromBatch: (vendor: number) => void;
};

const PaymentBatchCheckbox = ({
  isSelected,
  openPayable,
  allPayables,
  addPayableToBatch,
  removePayableFromBatch,
  addVendorToBatch,
  removeVendorFromBatch,
}: PaymentBatchCheckboxProps) => {
  return (
    <div className="capitalize">
      <Checkbox
        checked={isSelected}
        onChange={(e) => {
          e.originalEvent.stopPropagation();
          const payment = transformPayableToPayment(openPayable);

          if (e.checked) {
            if (payment.apref) {
              const vendorList = allPayables.filter((value: Payable) => {
                return (
                  payment.vendor === value.VendorCode && !value.BatchDisabled
                );
              });
              const vendorTotalRow = vendorList[vendorList.length - 1];
              const vendorPayment = transformPayableToPayment(vendorTotalRow);

              addPayableToBatch(payment, vendorList.length - 2, vendorPayment);
            } else {
              addVendorToBatch(payment, allPayables);
            }
          } else {
            if (payment.apref) {
              removePayableFromBatch(payment);
            } else {
              removeVendorFromBatch(payment.vendor);
            }
          }
        }}
      />
    </div>
  );
};

export default PaymentBatchCheckbox;
