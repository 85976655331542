import useParams from '../../hooks/useParamsAndCookies';
import { CashManagementFilterFields } from './CashManagementFilters';

const formatFilters = (filters: CashManagementFilterFields) => {
  return {
    ...filters,
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

const CMParams = () => {
  useParams<CashManagementFilterFields>({
    tab: 'CM',
    params: [{ label: 'account' }],
    formatFilters,
    filterChanges: (values) => {
      const cookies = values as { dates: (string | null)[] };
      const { dates, ...otherFields } = cookies;
      const newCookies = otherFields as Record<string, unknown>;
      if (dates) {
        const start = new Date(dates[0] ?? '');
        const end = dates[1] ? new Date(dates[1]) : null;
        newCookies['dates'] = [start, end];
      }

      return newCookies;
    },

    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default CMParams;
