import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import {
  PaymentBatch,
  PaymentBatchPayable,
} from '../Interfaces/Accounting/AccountsPayables.interfaces';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export type fetchAccountsPayablesDataArgs = {
  company: Company;
  info?: string;
  dates?: Date[];
  vendors?: string[];
};

export const fetchAccountsPayablesList = async (
  companyId: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/accountsPayables/list`, {
    signal,
    params: {
      companyId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const dashboard = response.data;
  return dashboard;
};

export const fetchAccountsPayablesData = async (
  params: fetchAccountsPayablesDataArgs & { reportType: string },
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(`${apiUrl}/api/accountsPayables`, {
    signal,
    params: {
      ...cleanedParams,
      companyID: company.id,
      source: company.source,
      dateEnd,
      dateStart,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const dashboard = response.data;
  return dashboard;
};

export const fetchAPOpenAged = async (
  params: fetchAccountsPayablesDataArgs,
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(`${apiUrl}/api/accountsPayables/openAged`, {
    signal,
    params: {
      ...cleanedParams,
      companyID: company.id,
      source: company.source,
      dateEnd,
      dateStart,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const dashboard = response.data;
  return dashboard;
};

export const fetchAPOpenPerJob = async (
  params: fetchAccountsPayablesDataArgs & { jobs?: string[] },
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(
    `${apiUrl}/api/accountsPayables/openPerJob`,
    {
      signal,
      params: {
        ...cleanedParams,
        companyID: company.id,
        source: company.source,
        dateEnd,
        dateStart,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const dashboard = response.data;
  return dashboard;
};

export const fetchAccountsPayablesResume = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/accountsPayables/resume`, {
    signal,
    params: { companyID: company.id, source: company.source },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const data = response.data;
  return data;
};

export const fetchAPOpenJobs = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/accountsPayables/openJobs`, {
    signal,
    params: { companyID: company.id, source: company.source },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export type fetchPayableDetailsArgs = {
  apref: string;
  aptrans: number;
  vendor: number;
  invDate: Date;
  company: Company;
};

export const fetchPayableDetails = async (
  params: fetchPayableDetailsArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsPayables/payablesDetails`,
    {
      signal,
      params: {
        companyID: params.company.id,
        source: params.company.source,
        apref: params.apref,
        aptrans: params.aptrans,
        vendor: params.vendor,
        invDate: params.invDate,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchPayableAccountsBalances = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsPayables/total-balances`,
    {
      signal,
      params: { source: company.source, companyID: company.id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postPaymentBatch = async (
  company: Company,
  batch: PaymentBatchPayable[]
) => {
  const response = await axios.post(
    `${apiUrl}/api/accountsPayables/paymentBatch`,
    { batch, source: company.source, companyID: company.id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putPaymentBatch = async (
  id: PaymentBatch['id'],
  batch: PaymentBatchPayable[]
) => {
  const response = await axios.put(
    `${apiUrl}/api/accountsPayables/paymentBatch/${id}`,
    { batch },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const submitPaymentBatch = async (id: PaymentBatch['id']) => {
  const response = await axios.put(
    `${apiUrl}/api/accountsPayables/paymentBatch/submit/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};
