import { useMutation } from '@tanstack/react-query';
import LoadingButton from 'apps/tmr-frontend/src/app/components/inputs/LoadingButton';
import ConfirmationDialog from 'apps/tmr-frontend/src/app/components/messages/ConfirmationDialog';
import ErrorToast, {
  showToast,
} from 'apps/tmr-frontend/src/app/components/messages/ErrorAlert';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import {
  DraftBatchPayment,
  PaymentBatch,
  PaymentBatchPayable,
} from 'apps/tmr-frontend/src/app/Interfaces/Accounting/AccountsPayables.interfaces';
import { ApiError } from 'apps/tmr-frontend/src/app/Interfaces/Generic.interfaces';
import {
  postPaymentBatch,
  putPaymentBatch,
  submitPaymentBatch,
} from 'apps/tmr-frontend/src/app/services/AccountsPayablesService';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';

type PaymentBatchProcessProps = {
  batch: DraftBatchPayment[];
  defaultDraftID?: PaymentBatch['id'];
  defaultBlockSave?: boolean;
  afterSubmitting?: () => void;
  afterSaving?: (draftID: string) => void;
};

const PaymentBatchProcess = ({
  batch,
  defaultDraftID,
  defaultBlockSave = true,
  afterSubmitting,
  afterSaving,
}: PaymentBatchProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const [blockSave, setBlockSave] = useState(defaultBlockSave);
  const [blockSubmit, setBlockSubmit] = useState(
    !defaultBlockSave || !defaultDraftID
  );
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const toast = useRef<Toast>(null);
  const prevDraft = useRef(batch);

  const save = useMutation({
    mutationFn: (draftID: string) => {
      const cleanedBatch = batch.reduce((acc, value) => {
        const { apref, vendor, aptrans, payType } = value;
        if (apref && vendor && aptrans && payType) {
          acc.push({ apref, aptrans, vendor, payType });
        }

        return acc;
      }, [] as PaymentBatchPayable[]);

      if (draftID) {
        return putPaymentBatch(draftID, cleanedBatch);
      }

      return postPaymentBatch(selectedCompany!, cleanedBatch);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Payment Batch',
        'The data was saved successfully!',
        3000
      );

      setBlockSave(true);
      setBlockSubmit(false);

      if (!draftID) {
        setDraftID(data);
      }

      if (afterSaving) {
        afterSaving(draftID ?? data);
      }
    },
    onError: (error: ApiError) => {
      const errorMessage = error.response?.data?.message;

      showToast(
        'error',
        toast,
        'Save Payment Batch',
        errorMessage ?? "The data couldn't be saved",
        3000
      );
    },
  });

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return submitPaymentBatch(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);
      setDraftID('');
      showToast(
        'success',
        toast,
        'Submit Payment Batch',
        'The data was submitted successfully!',
        3000
      );

      if (afterSubmitting) {
        afterSubmitting();
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Payment Batch',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  useEffect(() => {
    if (prevDraft.current !== batch) {
      setBlockSave(false);
      setBlockSubmit(true);
      prevDraft.current = batch;
    }
  }, [batch]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <LoadingButton
        label="Save"
        fontSize="text-xl"
        bgColor="buttonSecondary"
        isLoading={save.isLoading}
        onClick={() => save.mutate(draftID)}
        disabled={blockSave || save.isLoading}
      />
      <ConfirmationDialog
        Button={
          <LoadingButton
            label="Submit"
            fontSize="text-xl"
            isLoading={submit.isLoading}
            disabled={blockSubmit || submit.isLoading}
            type="button"
          />
        }
        onConfirm={() => submit.mutate(draftID)}
        message="Are you sure you want to submit this payment batch?"
      />
    </React.Fragment>
  );
};

export default PaymentBatchProcess;
