import { fetchContractBillingFilters } from './CBFilters';

import useParams from '../../hooks/useParamsAndCookies';

const params: {
  label: keyof fetchContractBillingFilters;
  isEncrypted?: boolean;
}[] = [
  { label: 'reportType' },
  { label: 'contract', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (
  filters: fetchContractBillingFilters,
  filtersToIgnore?: (keyof fetchContractBillingFilters)[]
) => {
  const newYear = filters.year ? filters.year.toString() : null;
  const formatttedFilters = { ...filters, year: newYear };

  if (filtersToIgnore) {
    filtersToIgnore.forEach((filter) => delete formatttedFilters[filter]);
  }

  return formatttedFilters;
};

const CBParams = () => {
  useParams<fetchContractBillingFilters>({
    tab: 'CB',
    params,
    formatFilters,
    filterChanges: (values) => {
      const cookies = values as { year: string | null };
      const { year, ...otherFields } = cookies;
      const newCookies = otherFields as Record<string, unknown>;
      if (year) {
        const newYear = new Date(year ?? '');
        newCookies['year'] = newYear;
      }

      return newCookies;
    },
    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default CBParams;
