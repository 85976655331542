import { JobContractFilters } from './JCFilters';
import useParams from '../../hooks/useParamsAndCookies';

const params: { label: keyof JobContractFilters; isEncrypted?: boolean }[] = [
  { label: 'reportType' },
  { label: 'job', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (filters: Partial<JobContractFilters>) => {
  return {
    ...filters,
    startDate: filters.startDate ? filters.startDate.toString() : null,
    endDate: filters.endDate ? filters.endDate.toString() : null,
  };
};

const JCParams = () => {
  useParams<JobContractFilters>({
    tab: 'JC',
    params,
    formatFilters,
    filterChanges: (values) => {
      const cookies = values as {
        startDate: string | null;
        endDate: string | null;
      };
      const { startDate, endDate, ...otherFields } = cookies;
      const newCookies = otherFields as Record<string, unknown>;
      if (startDate) {
        newCookies['startDate'] = new Date(startDate);
      }
      if (endDate) {
        newCookies['endDate'] = new Date(endDate);
      }

      return newCookies;
    },
    cookieFiltersName: 'filters',
  });

  return <span />;
};

export default JCParams;
