import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { nextSortState, sortByField } from '../../../../utils/sortUtils';
import {
  DraftBatchPayment,
  Payable,
  PaymentBatch,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import APDrilldown from '../APDrilldown/APDrilldown';
import { animateCellText } from '../../../../utils/htmlUtils';
import PaymentBatchProcess from './PaymentBatchProcess/PaymentBatchProcess';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import {
  comparePayableWithPayment,
  comparePayments,
  buildDefaultBatch,
  transformPayableToPayment,
} from '../apUtil';
import PaymentBatchCheckbox from './PaymentBatchProcess/PaymentBatchCheckbox';

export type APOpenTableProps = {
  accountsPayables: Payable[];
  isArchive?: boolean;
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
  draftID?: PaymentBatch['id'];
  defaultBatchPayables?: DraftBatchPayment[];
};

const APOpenTable = React.forwardRef<HTMLDivElement, APOpenTableProps>(
  (
    {
      accountsPayables,
      isArchive,
      createBatch,
      changeCreateBatch,
      draftID,
      defaultBatchPayables,
    },
    ref
  ) => {
    const { rolesAcess } = useRolesAccessContext();
    const access = rolesAcess?.find(
      (access) => access.report === 'create_payment_batch'
    );
    const [openPayables] = useState(accountsPayables);
    const [defaultValues, setDefaultValues] = useState({
      selectedPayables: buildDefaultBatch(
        openPayables,
        defaultBatchPayables ?? []
      ),
      paymentTotal:
        defaultBatchPayables?.reduce((acc, payment) => {
          const openPayable = accountsPayables.find((payable) =>
            comparePayableWithPayment(payable, payment)
          );

          if (!openPayable) {
            return acc;
          }

          return acc + openPayable.NetAmount + (openPayable.Retainage ?? 0);
        }, 0) ?? 0,
      draftID,
    });
    const [selectedPayables, setSelectedPayables] = useState<
      DraftBatchPayment[]
    >(defaultValues.selectedPayables);
    const [paymentTotal, setPaymentTotal] = useState(
      defaultValues.paymentTotal
    );
    const [sortState, setSortState] = React.useState<{
      field: string;
      order: DataTableSortOrderType;
    }>({ field: '', order: null });
    const [sortedTransactions, setSortedTransactions] = React.useState([
      ...openPayables,
    ]);
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();

    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let payableAmountTotal = 0;
    let taxTotal = 0;
    let discTotal = 0;
    let gross = 0;
    openPayables.forEach((payable: Payable) => {
      if (payable.Reference) {
        payableAmountTotal += payable.PayableAmount ?? 0;
        taxTotal += payable.Tax ?? 0;
        discTotal += payable.DiscountOffered ?? 0;
        gross += payable.Gross;
      }
    });

    const onRowClick = (e: DataTableRowClickEventParams) => {
      if (e.data.Reference) {
        setSelectedPayable(e.data);
        setShowDrilldown(true);
      }
    };

    const rowClassName = (data: Payable) => {
      let className = data.Reference ? 'cursor-pointer' : '';
      if (!data.Reference) {
        className = `${className} font-bold surface-300`;
      }

      return className;
    };

    const handleSort = (event: DataTablePFSEvent) => {
      const { reset, field, order } = nextSortState(
        event.sortField,
        sortState.field
      );
      setSortState({ field, order });

      if (reset) {
        setSortedTransactions([...openPayables]);
        return;
      }

      const sortedData = [...openPayables].sort((a, b) => {
        switch (event.sortField) {
          case 'Net': {
            const aValue = a.NetAmount + (a.Retainage ?? 0);
            const bValue = b.NetAmount + (b.Retainage ?? 0);
            return (order ?? 0) * (aValue - bValue);
          }
          default: {
            const key = event.sortField as keyof Payable;
            const aValue = a[key];
            const bValue = b[key];
            return sortByField(aValue, bValue, order ?? 0);
          }
        }
      });

      setSortedTransactions(sortedData);
    };

    const removePayable = (paymentToRemove: DraftBatchPayment) => {
      const newList = selectedPayables.filter(
        (payment) =>
          !comparePayments(paymentToRemove, payment) &&
          !(payment.vendor === paymentToRemove.vendor && !payment.apref)
      );
      setSelectedPayables(newList);
      setPaymentTotal((amount) => amount - paymentToRemove.amount);
    };

    const removeVendorFromDraft = (vendor: number) => {
      setSelectedPayables((currentList) => {
        let total = 0;
        const newList = currentList.filter((current) => {
          if (current.vendor === vendor) {
            total += current.apref ? current.amount : 0;

            return false;
          }

          return true;
        });

        setPaymentTotal((current) => current - total);

        return newList;
      });
    };

    const addPayable = (
      payable: DraftBatchPayment,
      vendorAmount: number,
      vendorTotal: DraftBatchPayment
    ) => {
      setSelectedPayables((list) => {
        const currentVendorAmount = list.filter(
          (current) => current.vendor === payable.vendor
        ).length;
        const newList = [...list, payable];

        if (currentVendorAmount === vendorAmount) {
          newList.push(vendorTotal);
        }

        return newList;
      });
      setPaymentTotal((amount) => amount + payable.amount);
    };

    const addVendorToBatch = (
      payment: DraftBatchPayment,
      allPayables: Payable[]
    ) => {
      setSelectedPayables((payments) => {
        const currentVendorJson = payments.reduce((acc, current) => {
          if (current.vendor === payment.vendor) {
            acc[`${current.vendor}-${current.apref}-${current.payType}`] = true;
          }

          return acc;
        }, {} as Record<string, boolean>);
        const vendorPayments: DraftBatchPayment[] = [];
        let total = 0;

        allPayables.forEach((value: Payable) => {
          if (
            value.VendorCode === payment.vendor &&
            value.Reference &&
            !currentVendorJson[
              `${value.VendorCode}-${value.Reference}-${value.PayType}`
            ] &&
            !value.BatchDisabled
          ) {
            const vendorPayment = transformPayableToPayment(value);
            vendorPayments.push(vendorPayment);

            total += vendorPayment.amount;
          }
        });

        setPaymentTotal((current) => current + total);
        return [...payments, ...vendorPayments, payment];
      });
    };

    const afterSaving = (draftID: string) => {
      setDefaultValues({
        selectedPayables,
        paymentTotal,
        draftID,
      });
    };

    const afterSubmitting = () => {
      selectedPayables.forEach((payment) => {
        const openPayable = openPayables.find(
          (payable) =>
            (payment.apref === payable.Reference &&
              payment.aptrans === payable.TransCode &&
              payment.vendor === payable.VendorCode &&
              payment.payType === payable.PayType) ||
            (!payment.apref &&
              !payable.Reference &&
              payment.vendor === payable.VendorCode)
        );

        if (openPayable) {
          openPayable.BatchDisabled = true;
        }
      });

      setPaymentTotal(0);
      setSelectedPayables([]);
      setDefaultValues({
        selectedPayables: [],
        paymentTotal: 0,
        draftID: undefined,
      });

      if (changeCreateBatch) {
        changeCreateBatch(false);
      }
    };

    useEffect(() => {
      if (!createBatch && access) {
        setSelectedPayables(defaultValues.selectedPayables);
        setPaymentTotal(defaultValues.paymentTotal);
      }
    }, [createBatch, defaultBatchPayables]);

    return (
      <div>
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
            extendedInfo={isArchive}
          />
        )}
        <Table
          id="open-table"
          ref={ref}
          data={sortedTransactions}
          className={`mx-3 dashboardOptionShadow tableFirstLineStyled dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowClick={useCallback(onRowClick, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          sortField={sortState.field}
          sortOrder={sortState.order}
          onSort={useCallback(handleSort, [sortState.field, openPayables])}
          hideColumns={true}
          selection={selectedPayables}
          nonHideableColumns={['Select']}
        >
          <Column
            field="Vendor"
            header="Vendor"
            body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
            headerClassName={`tableHeader font-normal justify-content-center`}
            style={{ minWidth: '300px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Reference"
            header="Inv. Number"
            style={{ minWidth: '150px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell text-center word-break-all"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.InvoiceDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            style={{ minWidth: '230px' }}
            body={(ap) => <div className="scroll-text">{ap.Description}</div>}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName={`tableFooter`}
            sortable
          />
          <Column
            field="DueDate"
            header="Due Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.DueDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive &&
              'block text-right border-top-2 border-transparent mt-4 limitBorder relative'
            }`}
            footer={isArchive && 'Total:'}
            sortable
          />
          <Column
            field="Gross"
            header="Gross"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Gross)}
            headerClassName="tableHeader font-normal justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            footer={isArchive && formatCurrency(gross)}
            sortable
          />
          <Column
            field="HoldCode"
            header="Hold Code"
            style={{ minWidth: '80px', maxWidth: '80px' }}
            body={(ap) => <div className="scroll-text">{ap.HoldCode}</div>}
            headerClassName="tableHeader font-normal justify-content-center text-center overflow-x-visible white-space-normal"
            className="text-standard blackText tableCell overflow-x-hidden whiteSpace-nowrap checkOverflow"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            sortable
          />
          {!isArchive && (
            <Column
              field="PayType"
              header="Pay Type"
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName="tableHeader font-normal"
              className="justify-content-center text-standard blackText tableCell"
              footer="Total:"
              footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
              sortable
            />
          )}
          <Column
            field="PayableAmount"
            header={'Payable Amount'}
            style={{ minWidth: '130px' }}
            body={(ap) => formatCurrency(ap.PayableAmount)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="Tax"
            header="Tax"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Tax)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(taxTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="DiscountOffered"
            header="Discount Offered"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.DiscountOffered)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="NetAmount"
            header={'Net Amount'}
            style={{ minWidth: '140px' }}
            body={(ap) => formatCurrency(ap.NetAmount + (ap.Retainage ?? 0))}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal + taxTotal - discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {createBatch && access && (
            <Column
              header="Select"
              body={(ap, options) => {
                if (ap.BatchDisabled) {
                  return;
                }

                return (
                  <PaymentBatchCheckbox
                    isSelected={selectedPayables.some((payment) =>
                      comparePayableWithPayment(ap, payment)
                    )}
                    openPayable={ap}
                    allPayables={options.props.value}
                    addPayableToBatch={addPayable}
                    removePayableFromBatch={removePayable}
                    addVendorToBatch={addVendorToBatch}
                    removeVendorFromBatch={removeVendorFromDraft}
                  />
                );
              }}
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName={`tableHeader font-normal`}
              className={`justify-content-center text-standard blackText tableCell p-0`}
              footerClassName="tableFooter"
            />
          )}
        </Table>
        {createBatch && access && (
          <div className="ml-auto w-full px-3 sm:px-0 sm:w-24rem mr-5 my-5 text-standard">
            <div className="flex justify-content-between mt-1">
              <span className="font-bold">Payment Batch Total</span>
              <span>{formatCurrency(paymentTotal)}</span>
            </div>
            <div className="mt-3 flex gap-4 w-fit ml-auto printHide">
              <PaymentBatchProcess
                batch={selectedPayables}
                afterSubmitting={afterSubmitting}
                afterSaving={afterSaving}
                defaultDraftID={defaultValues.draftID}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APOpenTableProps>,
  nextTransactions: Readonly<APOpenTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APOpenTable, transactionsAreEqual);
