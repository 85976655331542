import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import {
  EmployeePCReimbursement,
  PCReimbursementArgs,
  PCReimbursementDetails,
  PCReimbursementSummary,
} from '../../../Interfaces/Accounting/Payroll.interface';

const setEmpDetails = (emp: EmployeePCReimbursement) => {
  let hasDetails = false;
  emp.details?.forEach((detail) => {
    const keysLength = Object.keys(detail).length;

    hasDetails = hasDetails || !!keysLength;
  });

  emp.useDetails = hasDetails;
};

export const findEmployee = (
  employees: EmployeePCReimbursement[],
  empID: number
) => {
  let empIndex = 0;
  const selectedEmp = employees.find((emp, index) => {
    if (emp.empID === empID) {
      empIndex = index;
      return true;
    }

    return false;
  });

  if (!selectedEmp) {
    return;
  }

  return { index: empIndex, employee: selectedEmp };
};

export const updateDetails = (
  employee: EmployeePCReimbursement,
  detailIndex: number,
  detail: PCReimbursementDetails
) => {
  if (!employee.details) {
    return;
  }

  const empCopy = { ...employee };
  const { newDetails, selectedDetail } = empCopy.details!.reduce(
    (acc, prevDetail, index) => {
      if (index === detailIndex) {
        acc.selectedDetail = prevDetail;
        acc.newDetails.push(detail);
      } else {
        acc.newDetails.push(prevDetail);
      }

      return acc;
    },
    {
      newDetails: [] as PCReimbursementDetails[],
      selectedDetail: null as PCReimbursementDetails | null,
    }
  );

  if (!selectedDetail) {
    return;
  }

  empCopy.details = newDetails;

  const prevUseDetails = empCopy.useDetails;
  setEmpDetails(empCopy);
  if (!prevUseDetails && empCopy.useDetails) {
    empCopy.total = null;
    delete empCopy.amount;
    delete empCopy.job;
    delete empCopy.phaseCode;
    delete empCopy.overHead;
  }

  if (selectedDetail.amount !== detail.amount) {
    empCopy.total =
      (empCopy.total ?? 0) -
      (selectedDetail.amount ?? 0) +
      (detail.amount ?? 0);

    if (!empCopy.total) {
      empCopy.total = null;
    }
  }

  return empCopy;
};

export const addNewDetail = (employee: EmployeePCReimbursement) => {
  const empCopy = { ...employee };
  empCopy.details?.push({});
  empCopy.details = [...(empCopy.details ?? [])];

  return empCopy;
};

export const removeDetail = (
  employee: EmployeePCReimbursement,
  detailIndex: number
) => {
  if (!employee.details) {
    return;
  }

  const empCopy = { ...employee };
  const { remainingDetails, removedDetail } = empCopy.details!.reduce(
    (acc, detail, index) => {
      if (detailIndex === index) {
        acc.removedDetail = detail;
      } else {
        acc.remainingDetails.push(detail);
      }

      return acc;
    },
    {
      remainingDetails: [] as PCReimbursementDetails[],
      removedDetail: null as PCReimbursementDetails | null,
    }
  );

  if (!removedDetail) {
    return;
  }

  empCopy.total = (empCopy.total ?? 0) - (removedDetail.amount ?? 0);

  if (!empCopy.total) {
    empCopy.total = null;
  }

  empCopy.details = remainingDetails;
  setEmpDetails(empCopy);

  return empCopy;
};

export const updateAttachments = (
  employees: EmployeePCReimbursement[],
  prevEmployees: EmployeePCReimbursement[]
) => {
  prevEmployees.forEach((emp) => {
    const newEmp = employees.find((newEmp) => newEmp.empID === emp.empID);
    emp.attachments = newEmp?.attachments;

    if (emp.newAttachments) {
      delete emp.newAttachments;
    }

    if (emp.deleteAttachments) {
      delete emp.deleteAttachments;
    }
  });

  return [...prevEmployees];
};

export const buildFormDataPayload = (
  data: PCReimbursementArgs,
  extraKeys: Record<string, string>
) => {
  const formData = new FormData();

  Object.entries(extraKeys).forEach((value) => {
    const [key, content] = value;

    formData.append(key, content);
  });

  if (!data.length) {
    formData.append('employees', JSON.stringify([]));
    return formData;
  }

  data.forEach((employee, index) => {
    formData.append(
      `employees[${index}]`,
      JSON.stringify({
        empID: employee.empID,
        total: employee.total,
        details: employee.details,
        amount: employee.amount,
        job: employee.job,
        phaseCode: employee.phaseCode,
        overHead: employee.overHead,
        deleteAttachments: employee.deleteAttachments,
      })
    );

    if (employee.attachments) {
      employee.attachments.forEach((attachment, attachmentIndex) => {
        formData.append(
          `employees[${index}][attachments][${attachmentIndex}]`,
          attachment
        );
      });
    }
  });

  return formData;
};

export const formatReimbursementEmployees = (
  data: EmployeePCReimbursement[]
) => {
  const newData = data.map((emp) => {
    const empCoy = { ...emp };

    if (empCoy.details) {
      empCoy.useDetails = true;
    } else {
      empCoy.details = [{}];
    }

    return empCoy;
  });

  newData.push({ empID: null, total: null, details: [{}] });
  return newData;
};

export const checkReimbursementContent = (draft: EmployeePCReimbursement[]) => {
  let complete = true;
  const hasEmptyValue = (detail: PCReimbursementDetails) => {
    return (
      (!detail.job && !detail.overHead) || (detail.job && !detail.phaseCode)
    );
  };

  draft.forEach((emp) => {
    if (!emp.empID) {
      return;
    }

    if (emp.useDetails) {
      const { details } = emp;

      details?.forEach((detail) => {
        if (hasEmptyValue(detail)) {
          complete = false;
        }
      });
    } else {
      if (hasEmptyValue(emp)) {
        complete = false;
      }
    }
  });

  return complete;
};

export const prepareReimbursementPayload = (
  draft: EmployeePCReimbursement[]
) => {
  const processedDraft = draft.reduce((acc, emp) => {
    if (!emp.empID) {
      return acc;
    }

    const empCopy = {
      ...emp,
      empID: emp.empID,
      total: emp.total || 0,
      attachments: emp.newAttachments,
    };
    if (!empCopy.useDetails) {
      delete empCopy.details;
    } else {
      empCopy.details = empCopy.details?.reduce((acc, detail) => {
        if (Object.keys(detail).length > 0) {
          acc.push(detail);
        }

        return acc;
      }, [] as PCReimbursementDetails[]);
    }

    delete empCopy.useDetails;
    delete empCopy.firstName;
    delete empCopy.lastName;
    delete empCopy.newAttachments;

    acc.push(empCopy);

    return acc;
  }, [] as PCReimbursementArgs);

  return processedDraft;
};

export const getSubmittedDraft = (
  seledtedDraft: string,
  data: PCReimbursementSummary[]
) => {
  const draft = data?.find((draft) => draft.id === seledtedDraft);

  return `${formatUTCDate(draft?.updatedAt ?? '')} - ${draft?.userName}`;
};
