import { ReportItem } from '../app/Interfaces/Role.interfaces';

export const ROLE_TYPE_OPTIONS = [
  { label: 'Internal', value: 'internal' },
  { label: 'External', value: 'external' },
];

export const MODULES: Record<string, ReportItem[]> = {
  'cash management': [{ label: 'cash' }],
  'accounts payables': [
    { label: 'Open Payables', value: 'openPayables' },
    { label: 'Aged Payables', value: 'agedPayables' },
    { label: 'Payment History', value: 'paymentHistory' },
    { label: 'Unapproved Invoices', value: 'unapprovedInvoices' },
    { label: 'Invoice Archive', value: 'invoiceArchive' },
    { label: 'Vendor List', value: 'vendorList' },
    { label: 'Open Payables Per Job', value: 'openPayablesPerJob' },
  ],
  'accounts receivables': [
    { label: 'Aged Analysis', value: 'agedAnalysis' },
    { label: 'Contract Billings and Receipts', value: 'contractBAR' },
    { label: 'Receipts History', value: 'receiptsHistory' },
    { label: 'Customer List', value: 'customerList' },
  ],
  'contract billings': [
    { label: 'Contract List', value: 'contractList' },
    {
      label: 'Contract Progress Worksheet',
      value: 'contractProgress',
    },
    { label: 'Monthly Billing', value: 'monthlyBilling' },
  ],
  'jobs & contracts': [
    { label: 'Contract Analysis', value: 'contractAnalysis' },
    { label: 'Cost/Estimate', value: 'costEstimate' },
    {
      label: 'Projected JC Report',
      value: 'projectedJC',
    },
    { label: 'Cost Detail', value: 'costDetail' },
    { label: 'Cost and Revenue', value: 'costRevenue' },
    { label: 'Contract Items with CO Detail', value: 'itemDetails' },
    { label: 'MTD/YTD/JTD Costs', value: 'costs' },
    { label: 'Job Phases', value: 'jobPhases' },
    { label: 'Phase Master', value: 'phaseMaster' },
  ],
  payroll: [
    { label: 'Employee List', value: 'employee' },
    { label: 'Employee Earnings', value: 'earnings', blockAdmin: true },
  ],
  subcontracts: [
    { label: 'Last Payment Summary', value: 'paymentSummary' },
    { label: 'Subcontract Status Report', value: 'subcontractPayments' },
    {
      label: 'Subcontract Payment Worksheet',
      value: 'paymentWorksheet',
    },
  ],
  'financial statements': [
    {
      label: 'Financial Statements',
    },
  ],
  new: [
    { label: 'New Contract Setup' },
    { label: 'Schedule of Values' },
    { label: 'Job Phase Codes' },
    { label: 'Estimated Cost' },
    { label: 'PO Entry' },
    { label: 'Change Orders' },
    { label: 'New Subcontract Setup' },
    {
      label: 'New PR Timesheet',
    },
    {
      label: 'Create Payment Batch',
    },
  ],
};

const getSectionReports = (section: string) => {
  const sectionReports = MODULES[section];

  if (!sectionReports) {
    return [];
  }

  const reports = sectionReports.map((report) =>
    report.label.replace(/ /g, '_').toLocaleLowerCase()
  );

  return reports;
};

export const reportsPerSection: Record<string, string[]> = {
  'cash management': getSectionReports('cash management'),
  'accounts payables': [
    ...getSectionReports('accounts payables'),
    'create_payment_batch',
  ],
  'accounts receivables': getSectionReports('accounts receivables'),
  'contract billings': getSectionReports('contract billings'),
  'jobs & contracts': getSectionReports('jobs & contracts'),
  payroll: getSectionReports('payroll'),
  subcontracts: getSectionReports('subcontracts'),
  'financial statements': getSectionReports('financial statements'),
  new: [
    ...getSectionReports('new'),
    'contract_progress_worksheet',
    'subcontract_payment_worksheet',
  ],
};
